<template>
  <span class="ui-orders-count" :class="classes">
    <slot />
    <span
      class="ui-orders-count__badge"
      :class="badgeColor"
      v-if="haveIncidents()"
    >
      <font-awesome-icon icon="exclamation-triangle" />
    </span>
  </span>
</template>

<script>
export default {
  name: "OrdersCount",

  props: {
    total: {
      type: Number,
      default: 0,
    },
    open: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
    },
    variant: {
      type: String,
      default: "badge",
    },
  },

  computed: {
    badgeColor() {
      return this.open > 0
        ? `ui-orders-count__badge--warning`
        : `ui-orders-count__badge--normal`;
    },

    classes() {
      return {
        [`bg-orders-${this.color}`]: this.color,
        "ui-orders-count--bullet": this.variant === "bullet",
      };
    },
  },

  methods: {
    haveIncidents() {
      return this.total > 0;
    },
  },
};
</script>
